// Core
body {
  .dashboard-paper-left {
    height: 390px;
    border-radius: 25px;
    margin-top: 30px;
  }

  .dashboard-paper-right {
    height: 390px;
    border-radius: 25px;
  }
  .dashboard-paper-grid {
    height: 150px;
    border-radius: 25px;
  }
  .dashboard-paper-grid1 {
    text-align: center;
  }
  .dashboard-paper-logo1 {
    height: 10px;
  }
}

.downLine {
  width: 25%;
  background: linear-gradient(to top right, transparent calc(50% - 1px), #aaa, transparent calc(50% + 1px));
  margin-top: 15px;
  margin-bottom: 15px;
}

.upLine {
  width: 25%;
  background: linear-gradient(to bottom right, transparent calc(50% - 1px), #aaa, transparent calc(50% + 1px));
  margin-top: 15px;
  margin-bottom: 15px;
}

.apexcharts-canvas svg {
  border-radius: 10px;
  box-shadow: 0 6px 10px #00000005, 0 0 6px #00000005 !important;
}

.pac-container {
  background-color: #fff;
  position: absolute !important;
  z-index: 9999 !important;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  font-family: Arial, sans-serif;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.MuiPaper-rounded {
  border-radius: 0.2rem !important;
}
