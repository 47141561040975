// Core
body {
  .button-common-primary {
    border-radius: 5%;
  }
  .button-common-danger {
    border-radius: 5%;
    background-color: #ff4848;
    :hover {
      background-color: #ff5a5a;
    }
  }

  .acc-level-details {
    border-top: 2px solid #3d4977;
  }

  .acc-level-details-table .MuiPaper-elevation2 {
    box-shadow: none;
  }

  .acc-level-details-card {
    margin-top: 0%;
  }

  .acc-level-details-table .MuiTableCell-root {
    display: table-cell;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    font-size: 14px;
    text-align: left;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.43;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    letter-spacing: 0.01071em;
    vertical-align: inherit;
  }

  .field-label {
    width: 95%;
  }
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-radius: 8px;
  }
  .MuiTableHead-root {
    position: sticky;
  }

  .field-label-tree {
    margin-left: 0% !important;
  }

  .field-container {
    margin-top: 5% !important;
  }

  .field-container-tree {
    margin-right: 4%;
  }
  .ant-select-dropdown {
    z-index: 9999;
  }

  .text-fields {
    width: 90%;
  }

  .MuiTypography-h6 {
    text-align: center;
    color: white;
  }
  .MTableToolbar-highlight-45 {
    padding-right: 8px;
    background-color: rgb(186, 181, 181);
  }
}

.card{
  background: #fff;
  transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12) !important;
  cursor: pointer;
}

.card:hover{
transform: scale(1.05);
box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.MuiPaper-elevation1 {
  box-shadow: 0 6px 10px rgba(0,0,0,.02), 0 0 6px rgba(0,0,0,.02) !important;
}

.custom-modal .MuiPaper-rounded {
  box-shadow: 0px 3px 6px #00000029;

  .MuiDialogTitle-root {
    flex: 0 0 auto;
    margin: 0;
    padding: 8px 24px;
    background: $primary;
  }

  .MuiButton-root {
    border-radius: 0.2rem;
  }

  .MuiDialogActions-root {
    justify-content: center;
  }
  .css-wfb9cg {
    padding: 0px;
  }
}

.MuiSvgIcon-colorSecondary {
  color: $primary !important;
}
.MuiButton-root {
  text-transform: none !important;
}

.confirm-modal .MuiPaper-rounded {
  box-shadow: 0px 3px 6px #00000029;

  .MuiDialogTitle-root {
    flex: 0 0 auto;
    margin: 0;
    padding: 8px 24px;
    background: $primary;
    min-width: 300px;
  }

  .MuiButton-root {
    border-radius: 0.2rem;
  }
}
.custom-person-button-container .MuiDialogActions-root {
  flex: 0 0 auto;
  display: flex;
  padding: 8px;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  align-items: center;
  justify-content: center !important;
  background: #f5f5f5 0% 0% no-repeat padding-box !important;
  border-radius: 0px 0px 15px 15px !important;
}
.MuiSvgIcon-colorSecondary {
  color: $primary !important;
}
.MuiButton-root {
  text-transform: none !important;
}

.custom-filter-modal {
  @extend .confirm-modal;
}

.custom-link {
  text-align: left;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.MuiButton-root {
  border-radius: 0.2rem !important;
}

.custom-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 0.5px solid #00000029;
  border-radius: 3px;
  opacity: 1;
  min-width: 100%;
  min-height: 74vh;
  margin-bottom: 70px;
  border-radius: 14px;
}
.custom-person-container {
  min-width: 100%;
  min-height: 55vh;
  margin-bottom: 5px;
}

.custom-container-header {
  border-bottom: 0.5px solid #00000029;
}

.MuiDialogActions-root {
  flex: 0 0 auto;
  display: flex;
  padding: 8px;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  align-items: center;
  border-radius: 0px 0px 15px 15px !important;
}

.MuiDataGrid-root .MuiDataGrid-window {
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  overflow-x: hidden !important;
}

.custom-scrollbar {
  overflow-y: hidden;

  :hover {
    overflow-y: scroll;
  }
}

.is-active {
  font-weight: bold;
}

.filter-dialog .MuiPaper-rounded {
  .MuiDialogTitle-root {
    flex: 0 0 auto;
    margin: 0;
    background: #fff !important;
    color: white;
  }
  .MuiTypography-h6 {
    text-align: left;
    color: #3d4977;
  }
  .MuiButton-root {
    border-radius: 0.2rem;
  }
}

.zkteco-logo,
.zkteco_logo {
  height: 12px;
  margin-left: 5px;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8ad;
  z-index: 9999;
}
.loader {
  left: 56%;
  top: 50%;
  z-index: 1000;
  position: absolute;
}

.custom-modal .MuiPaper-rounded {
  box-shadow: 0px 3px 6px #00000029;

  .MuiTypography-h5 {
    color: white;
  }

  .MuiDialogTitle-root {
    flex: 0 0 auto;
    margin: 0;
    padding: 8px 24px;
    background: $darkBlue;
    color: white;
  }

  .MuiButton-root {
    border-radius: 0.2rem;
  }
  .MuiDialogActions-root {
    justify-content: center;
  }
  .close_icon {
    width: 25px;
    height: 25px;
  }
  .close_icon:hover {
    opacity: 0.6;
    cursor: pointer;
  }
}
.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  line-height: 25px;
  height: 35px;
  width: 100% !important;
  outline: none;
}
.react-tel-input .country-list {
  text-align: left;
}
.react-tel-input .country-list .country-name {
  margin-left: 25px;
}
