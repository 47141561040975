$container-height: 100px;

// Core
body {
  .device-list-container {
    margin-top: 1%;
    min-height: $container-height;
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border: 0.5px solid #bcc4ff;
    border-radius: 3px;
    opacity: 1;
  }

  .verify-company {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 11px #00000029;
    border-radius: 10px;
    opacity: 1;
    min-height: 20%;
  }

  .box-company-validate {
    margin: 10% 0%;
    background: #3d4977 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    min-height: 300px;
    width: 50%;
  }
  .box-company-logo {
    margin: 15% 0%;
  }
  .box-company-form {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 12px #00000029;
    opacity: 1;
    min-height: 100%;
    padding: 10% 0% 5% 0%;
  }
  .button-common-primary {
    border-radius: 5%;
    background-color: #3d4977;
  }

  .device-list-card {
    padding: 0%;
    border: none;
    min-height: $container-height;
    width: 100%;
  }

  .device-list-datagrid {
    padding: 0%;
    margin: 0%;
    border: none;
    width: 100%;
  }

  .device-list-actions {
    border-bottom: none;
  }

  .device-list-action-btn {
    font: normal normal normal 16px/21px Roboto;
    opacity: 1;

    letter-spacing: 0px;
    color: #263055;
    font-size: 15px;
  }

  .dialog-timeperiod .MuiDialog-paper {
    border-radius: 0px !important;
  }

  .dialog-content-timeperiod {
    margin: 0% 5% 5% 5%;
  }

  .dialog-timeperiod-header {
    background: #3d4977 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000080;
    opacity: 1;
    color: white;
    text-align: center;
  }

  .dilog-form {
    max-width: 750px;
  }

  .dilog-form-header {
    text-align: center;
    padding: 10px;
    text-align: center;
    background: #3d4977;
    color: white;
    font-size: 15px;
  }

  .dilog-form-closeButton {
    position: absolute;
    background: red;
    color: white;
    top: 8px;
    right: 20px;
    width: 10px;
    height: 10px;
  }

  .dilog-form-form {
    width: 1000px;
  }

  .dilog-form-radio {
    margin-top: 20px;
    margin-left: 5px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    color: gray;
  }

  .dilog-form-buttons {
    justify-content: center;
    margin-top: 10%;
  }

  .form-timeperiod {
    margin: 0% 10%;
  }

  .table-timeperiod-container {
    background: #f4f5fd 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000080;
    border: 0.5px solid #bcc4ff;
    border-radius: 3px;
    opacity: 1;
    max-width: 80%;
  }

  .table-timeperiod-border {
    text-align: center;
    border: 0.5px solid #9a9a9a;
  }

  .table-timeperiod-header {
    background: #dbe4ff 0% 0% no-repeat padding-box;
  }

  .dilog-form-adddoor-action {
    justify-content: center;
  }

  .search-root {
    padding: 0px 10px;
    display: flex;
    align-items: center;
    width: 300px;
    margin-bottom: 0px;
    border-radius: 40px;
  }

  .search-input {
    flex: 1;
  }

  .search-iconButton {
    padding: 5px;
    border-radius: 16px;
  }

  .card-header-filter {
    float: right;
  }

  .card-header-box {
    padding: 30px;
  }

  .card-header-search {
    width: 300px;
    border: 2px solid #ccc;
    border-radius: 16px;
    font-size: 16px;
    background-color: white;
  }

  .dilog-filter-head {
    text-align: left;
    padding: 10px;
    background: #3d4977;
    color: white;
    font-size: 15px;
  }

  .custom-search {
    padding: 0px 10px;
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    width: 300px;
  }

  .acc-head {
    color: #3d4977;
    font-size: 15px;
  }

  .acc-error {
    color: red;
    font-size: 12px;
  }

  .acc-list-action-btn {
    letter-spacing: 0px;
    color: #263055;
    font-size: 15px;
  }

  .refresh-btn {
    width: 20px;
    height: 20px;
  }

  .delete-btn {
    width: 20px;
    height: 20px;
  }

  .dilog-view-details {
    padding-left: 270px;
    border-radius: 0px;
    width: 100%;
    padding-top: 78px;
  }
}
.dilog-form-buttons {
  justify-content: center;
  margin-top: 10%;
}
