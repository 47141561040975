// Core

@import "core";

// Fonts

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

// Widgets

@import "widgets";

// Components

@import "components";

// Layout Components

@import "layout-components";

// Layout BlueprintsMuiBox-root

@import "layout-blueprints";

  
 .EmployeeButton{
    margin: 10px;  
 }

 .CapacityNames{
  padding-bottom :15px;
  color:black;
 }

 .user-profile .MuiMenu-paper{
    height:52% ;
 }
 .MuiDialogContent-root {
   flex: 1 1 auto;
   padding: 8px 24px;
   overflow-y: hidden !important;
 }

 .breadcrumb_link{
   font-size: 16px;
   text-align: left;
   color: #3d4977;
   padding: 12px 12px 12px 0;
 }

 .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
   font-size: 14px !important;
 }

