  // Core
body {

  .i18-control{
    display: flex;
  }

  .i18-control-inputlabel{
    color: white;
  }

  .i18-control-select{
    color: white;
  }
}
