.list-container {
  margin-top: 1% !important;
  min-height: $container-height;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 0.5px solid #bcc4ff;
  border-radius: 3px;
  opacity: 1;
}

.list-card {
  padding: 0%;
  margin: 0%;
  border: none;
  min-height: $container-height;
  width: 100%;
}

.list-datagrid {
  padding: 0%;
  margin: 0%;
  border: none;
  width: 100%;
}

.list-actions {
  border-bottom: none;
}

.list-action-btn {
  text-align: left;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: 0px;
  color: #263055;
  opacity: 1;
}

.table-top-link {
  margin-top: 4% !important;
}

.form-feilds-container {
  text-align: center;
  margin-bottom: 5px;
}

.search-filter {
  float: right;
  display: flex;
  padding: 0% 5%;
}
.search-filter .search-filter-chd {
  padding: 2%;
}
.search-filter-chd .filter {
  padding-top: 12%;
}

.search-filter-chd > a {
  margin-top: 10%;
}

#customized-dialog-title .MuiTypography-h6 {
  color: white !important;
}

.react-tel-input .form-control {
  width: 100% !important;
  height: 40px;
  border-radius: 0.5rem;
}
p {
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 0rem;
}
.react-tel-input .special-label {
  color: #1976d2 !important;
  display: none;
}
