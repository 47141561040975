// Core
body {

  .app-header-logo {
    height: $header-height;
    width: $sidebar-width;

    @include media-breakpoint-down(md) {
      display: none;
    }

    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, .08);
    padding-left:  1rem;;
    padding-right:  1rem;;
    justify-content: space-between;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }

  .app-header-logo-img {
    width: 50%;
    height: 50%;
  }

  .header-logo-wrapper {
    display: flex;
    align-items: center;
  }

  .header-logo-wrapper-btn {
    padding: ($spacer / 3);
    color: $white;
    background: rgba(255, 255, 255, .09);

    &:hover {
      background: rgba(255, 255, 255, .14);
    }
  }

  .header-logo-wrapper-link {
    text-decoration: none;
  }

  .header-logo-text {
    padding-left: $spacer / 2;
    font-size: $font-size-base * 1.1;
    font-weight: bold;
    width: auto;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }

  .header-logo {
    width: 100%;
  }
}
