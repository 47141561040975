// Core

body {
.app-content{
  flex: 1 1;
    display: flex;
    padding: 0;
    flex-direction: column;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    padding-left: 5px;
}
  .app-sidebar-wrapper-lg {
    min-height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding-top: 0;
    width: $sidebar-width;
  }

  .app-sidebar-menu {
    height: calc(100vh - #{$header-height});
  }

  .app-sidebar-wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 60;
    position: static;
    width: $sidebar-width;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }

  .app-sidebar-wrapper-fixed {
    background-color: honeydew;
  }
  .app-content-sidebar-fixed{
    position: relative !important;
     padding-left: 100px;
  }
  .app-sidebar-logo {
    width: 32px;
    height: 32px;
  }
 .app_container1{
  margin-left: 250px;
  width: 100%;
  @include media-breakpoint-down(md) {
    margin-left: auto;
  }
  }

 .app_container2{
  width: 100%;
  margin-left: 80px;
  }

  .app-sidebar-icon{
    width: 20px;
    height: 20px;
  }
  
  .sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
    min-width: 270px !important;
  }
  .sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02{
    background-color: gray;
  }
   .app-sidebar-menu{
    height: calc(100vh - 78px);    
      position: fixed;
   }
   .test-css{
     width:100px ;
     position: fixed;
     background-color: #fff !important ;
     margin-top: calc(5rem - 1px);  
    //  box-shadow: '0 6px 10px rgb(0 0 0 / 2%), 0 0 6px rgb(0 0 0 / 2%) !important';  
    box-shadow: 0px 1px 4px #00000029;
   }
   .sidenav---navtext---1AE_f:hover {
     color: aliceblue !important;
   }
   .sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:hover > .sidenav---navitem---9uL5T {
    background: #3d497714 !important;
    color: #fff;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover{
  background-color: #b7bed79c;
}
.app-content--inner {
  @include media-breakpoint-down(md)
  {
    padding: 7rem 2rem 1rem;
  }
  }
  .MuiDrawer-paper {
    @include media-breakpoint-down(md)
    {
      height: auto;
    }
  }
  .app-sidebar-wrapper-lg {
    @include media-breakpoint-down(md)
    {
      z-index: 0 !important;
    }
  }
  .sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv .sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---sidenav-subnav---1EN61 {
    display: none !important;
    }
  
    .sidenav---sidenav-subnav---1EN61:hover{
      display: none;
    }
  }
