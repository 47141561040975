.background-image {
  background-color: '#eaedef';
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  height: 100%;
  width: 100%;
  background-position: center;
}
.container_center {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.background-image .MuiOutlinedInput-root {
  position: relative !important;
  border-radius: 0.2rem !important;
}
.hrline_Right {
  width: 100px;
  height: fit-content;
  align-items: center;
  margin-left: 10px;
  justify-content: center;
  margin-top: 8px !important;
}
.hrline_Left {
  width: 100px;
  height: fit-content;
  align-items: center;
  margin-right: 10px;
  justify-content: center;
  margin-top: 8px !important;
}
.signIn {
  justify-content: center;
  flex-direction: row;
  display: flex;
}
.icon_button {
  border: 1px solid #bbeaff !important;
  margin: 5px 15px !important;
}
.social_icons {
  width: 20px;
}
.paper_right {
  display: flex;
  padding: 50px 80px;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 1px 4px #00000029;
  border-radius: 3px;
  opacity: 1;
  justify-content: center;
  align-items: 'center';
  text-align: 'center';
}
.paper_left {
  display: flex;
  padding: 10px;
  padding-top: 60px;
  align-items: center;
  flex-direction: column;
  background-color: #194084;
}
.left_img {
  width: 100%;
  margin: auto;
  display: block;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.login_icons {
  color: #b8c4d9 !important;
  margin: 10px;
}
.visibilityIcons {
  color: #b8c4d9 !important;
  font-size: 18px !important;
}
.forgot_password {
  color: #2399e7 !important;
  font-size: 13px !important;
}
.background-image .login_input .MuiInputBase-root .MuiOutlinedInput-input {
  font-size: 14px !important;
}
.background-image .login_input .MuiInputLabel-outlined {
  font-size: 14px !important;
}
.background-image .verify_company .MuiInputLabel-outlined {
  font-size: 14px !important;
  /* padding-top: 5px !important; */
  /* transform: translate(14px, 16px) scale(1) !important; */
}
.background-image .MuiFormControl-root.MuiTextField-root {
  margin: 13px 0;
}
.background-image .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
  padding: 7px !important;
}
.background-image .check_box .MuiTypography-body1 {
  font-size: 12px !important;
}
.background-image .check_box .MuiCheckbox-colorPrimary.Mui-checked {
  color: #1090e5;
}
.signin_title {
  margin-bottom: 20px !important;
  font-weight: 400 !important;
  font-size: 26px !important;
  color: #194084 !important;
}
.main_conatiner {
  min-height: 75vh;
}
.link {
  line-height: 30px;
  color: #1090e5 !important;
  font-size: 13px;
}
.footer {
  /* position: fixed; */
  left: 0;
  bottom: 8px;
  width: 100%;
  text-align: center;
}
@media (max-width: 426px) {
  .background-image {
    background-color: #194084 !important;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    height: 100%;
    width: 100%;
    background-position: center;
    overflow-x: hidden;
  }
  .paper_right {
    padding: 30px 20px;
    margin: 30px 0px !important;
  }
  .paper_left {
    padding: 10px;
    padding-top: 10px;
    margin: 0px !important;
    background-color: transparent;
  }
  .left_img {
    width: 100%;
    margin: auto;
    display: none;
  }
  .signin_title {
    margin-bottom: 10px !important;
    /* font-weight: 500 !important; */
    font-size: 22px !important;
  }
  .hrline_Left {
    width: 70px;
  }
  .hrline_Right {
    width: 70px;
  }
  .icon_button {
    margin: 3px 8px !important;
  }
  .main_conatiner {
    min-height: 40vh;
  }
  .container_center {
    height: auto;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }
}
@media (max-width: 1024px) {
  .main_conatiner {
    margin-top: 40px;
    min-height: 94vh;
  }
}
.login_input_password {
  -webkit-text-security: disc !important;
  font-size: 2px !important;
}
.MuiInputLabel-outlined {
  -webkit-text-security: none !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}

.RegisterUserGrid {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
